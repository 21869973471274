import React from 'react';
import classNames from 'classnames';
import { FaPhoneSquareAlt } from 'react-icons/fa'
import { MdEmail, MdContactMail } from 'react-icons/md'
import { BsLinkedin } from 'react-icons/bs'
import { MobileView, BrowserView } from 'react-device-detect';
import Button from '../elements/Button';
import { VCardData } from '../../utils/VCardGenerator';

const AdamContact = ({
    className,
    topOuterDivider,
    bottomOuterDivider,
    topDivider,
    bottomDivider,
    hasBgColor,
    invertColor,
    ...props

}) => {

    const createVCard = () => {
        const element = document.createElement("a");
        const file = new Blob([VCardData()], {
          type: "text/vcard"
        });
        element.href = URL.createObjectURL(file);
        element.download = "AdamRudy.vcf";
        document.body.appendChild(element);
        element.click();
    }

    const innerClasses = classNames(
        'hero-inner section-inner',
        topDivider && 'has-top-divider',
        bottomDivider && 'has-bottom-divider'
    );

    const outerClasses = classNames(
        'hero section center-content',
        topOuterDivider && 'has-top-divider',
        bottomOuterDivider && 'has-bottom-divider',
        hasBgColor && 'has-bg-color',
        invertColor && 'invert-color',
        className
      );
    return (
        <section
        {...props}
        className={outerClasses}
        >
        <div className="container-sm">
            <div className={innerClasses}>
                <h1 className='reveal-from-bottom' data-reveal-delay="100">Hey, it's <span className="text-color-primary">Adam!</span></h1>
                <p>Welcome to my page! Feel free to grab my details!</p>
                <p>BTW, my pronouns are <a href="/pronouns">they/them</a></p>
                <MobileView>
                   <p> <Button tag="button" onClick={createVCard} color="primary" ><MdContactMail/> Add to contacts</Button></p>
                   <p><a href="tel:+6786022545"><FaPhoneSquareAlt/> +1 (678) 602-2545</a></p>
                </MobileView>
                <BrowserView>
                <p><FaPhoneSquareAlt/> +1 (678) 602-2545</p>
                </BrowserView>
                
                <p><a href="mailto:adam@rudy.fyi"><MdEmail /> adam@rudy.fyi</a></p>
                <p><a href="https://www.linkedin.com/in/adamrudy/"><BsLinkedin />adamrudy</a></p>
            </div>

        </div>
        </section>
    )
};


export default AdamContact;
