import React from 'react';
import classNames from 'classnames';


const PronounsSection = ({
    className,
    ...props
    
}) => {
    const innerClasses = classNames(
        'hero-inner section-inner',
    );

    const outerClasses = classNames(
        'hero section center-content',
        className
      );
    return (
        <section
        {...props}
        className={outerClasses}
        >
             <div className="container-sm">
            <div className={innerClasses}>
            <h1 className='reveal-from-bottom' data-reveal-delay="100">Hey, it's <span className="text-color-primary">Adam!</span></h1>
            <h3 className='reveal-from-botton' data-reveal-delay="250">My pronouns are <span style={{ whiteSpace: 'nowrap' }} className="text-color-secondary">they/them/theirs</span></h3>
            <div className="ta-l">
                <h4>Understanding the meaning behind the pronouns.</h4>
                <p>While many people identify as "male" (or man) or "female" (or woman), not all individuals identify into the binary gender categories.</p>
                <p>In my case, I identify as non-binary. I'm not a man nor woman. I'm an individual.</p>

                <p>I use the pronouns they/them/theirs since pronouns such as he/him/his or she/her/hers are gender associated pronouns.</p>
                <h5>Here's some additional resources:</h5>
                <ul>
                    <li><a rel="noreferrer" target="_blank" href="https://transequality.org/issues/resources/understanding-non-binary-people-how-to-be-respectful-and-supportive">Understanding Non-Binary People: How to Be Respectful and Supportive</a></li>
                    <li><a rel="noreferrer" target="_blank" href="https://en.wikipedia.org/wiki/Non-binary_gender">Non-binary Gender [Wikipedia]</a></li>
                    <li><a rel="noreferrer" target="_blank" href='https://pronouns.org/they-them'>They/Them Pronous</a></li>
                </ul>
            </div>
            </div>
            </div>
        </section>
    )
};

export default PronounsSection;