import React from 'react';
// import sections
import PronounsSection from '../components/sections/PronounsSection'

const Pronouns = () => {

  return (
    <>
      <PronounsSection className="illustration-section-01" />
    </>
  );
}

export default Pronouns;