import React from 'react';
// import sections
import AdamContact from '../components/sections/AdamContact'

const Home = () => {

  return (
    <>
      <AdamContact className="illustration-section-01" />
    </>
  );
}

export default Home;